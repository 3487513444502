import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router';

import requests from './request/request'   // 记得改为你的路径
Vue.prototype.request = requests  // 此处命名为rq,你可以改


Vue.config.productionTip = false

// import Vant from 'vant';
import 'vant/lib/index.css';

// Vue.use(Vant);


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')

