import axios from 'axios'
import store from '@/store'
import { Toast } from 'vant';

// 创建 axios 实例
const requests = axios.create({
  baseURL: process.env.VUE_APP_API, // 基础url,如果是多环境配置这样写，也可以像下面一行的写死。
  timeout: 30000, // 请求超时时间
})

// 错误处理函数
const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = store.getters.token;
    if (error.response.status === 403) {
      Toast.fail({ message: data.message || data.msg, duration: 0 });

    }
    if (error.response.status === 401) {
      Toast.fail({ message: '你没有权限。', duration: 0 });
      if (token) {
        store.dispatch('logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor(请求拦截器)
requests.interceptors.request.use(config => {
  const token = store.getters.token;
  if (token) {
    config.headers['x-access-token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  config.headers['x-platform'] = "h5"
  config.headers['x-appid'] = process.env.VUE_APP_APPID;
  config.headers['x-version'] = "1.0.1"
  return config
}, err)


// response interceptor（接收拦截器）
requests.interceptors.response.use((response) => {
  const res = response.data
  if (res.code !== 1 && res.code !== 200) {
    return res
  } else {
    return res
  }
}, err)

export default requests;