const state = {
    userinfo: null,
    token: ''
  }
  
  if (localStorage && localStorage.token && localStorage.token != 'null') {
    state.token = localStorage.token
  }
  
  const mutations = {
    SET_USERINFO: (state, info) => {
      state.userinfo = info
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_LOGOOUT: (state) => {
      state.token = "";
      localStorage.token = "";
    },
  }
  
  const actions = {
    setUserinfo({
      commit
    }, info) {
      return new Promise(resolve => {
        commit('SET_USERINFO', info)
        resolve(true)
      })
    },
    setToken({
      commit
    }, token) {
      return new Promise(resolve => {
        if (localStorage) {
          localStorage.token = token
        }
        commit('SET_TOKEN', token)
        resolve(true)
      })
    },
    logout({
      commit
    }) {
      commit('SET_LOGOOUT');
      return true;
    }
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  