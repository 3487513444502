import Vue from 'vue';
import Router from 'vue-router';
import store from "@/store";

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      meta: {
        hideInMenu: true,
        title: '有得钻',
        noValidatePrivilege: true
      },
      component: () => import('@/views/index/index.vue')
    },
    {
      path: '/register/:inviteCode',
      name: 'register',
      meta: {
        hideInMenu: true,
        title: '有得钻-新用户注册',
        noValidatePrivilege: true
      },
      component: () => import('@/views/register/index.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        hideInMenu: true,
        title: '有得钻-用户登录',
        noValidatePrivilege: true
      },
      component: () => import('@/views/login/index.vue')
    },
    {
      path: '/agreement/yhxy',
      name: 'yhxy',
      meta: {
        hideInMenu: true,
        title: '用户协议',
        noValidatePrivilege: true
      },
      component: () => import('@/views/agreement/yhxy')
    },
    {
      path: '/agreement/yszc',
      name: 'yszc',
      meta: {
        hideInMenu: true,
        title: '隐私政策',
        noValidatePrivilege: true
      },
      component: () => import('@/views/agreement/yszc')
    },
    {
      path: '/download',
      name: 'download',
      meta: {
        hideInMenu: true,
        title: '下载应用',
        noValidatePrivilege: true
      },
      component: () => import('@/views/download/index')
    },
    {
      path: '/pay',
      name: 'pay',
      meta: {
        hideInMenu: true,
        title: '有得钻-开通服务',
        noValidatePrivilege: true,
        isLogin: true
      },
      component: () => import('@/views/pay/index.vue')
    },
  ]
});

// 解决路由跳转相同的地址报错
const originalPush = Router.prototype.push;
Router.prototype.push = function (location) {
  try {
    return originalPush.call(this, location).catch(err => err);
  } catch (error) {
    // TODO zhuoda sentry
    console.error(error);
  }
};


router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  if (to.meta.isLogin) {
    let token = store.getters.token;
    if (!token || token == "") {
      const redirectUrl = parseParams(to.path, to.query).toString();
      next({
        path: `/login?redirect=${redirectUrl}`,
        replace: true
      })
    } else {
      next()
    }
  } else {
    next()
  }
})


// 拼url的方法
const parseParams = (uri, params) => {
  const paramsArray = []
  Object.keys(params).forEach(key => params[key] && paramsArray.push(`${key}=${params[key]}`))
  if(paramsArray.length == 0) return uri;
  if (uri.search(/\?/) === -1) {
    uri += `?${paramsArray.join('&')}`
  } else {
    uri += `&${paramsArray.join('&')}`
  }
  return uri
}

export default router;