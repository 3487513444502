<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
}
input{
  border-color:unset;
  border-width:unset;
  border-style: unset;
  width: -webkit-fill-available;
}
button{
  border-width: unset;
  border-style:unset;
  border-color: unset;
  border-image:unset;
  width: -webkit-fill-available;
}
</style>
